import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Aboutus from './Pages/AboutPage/Aboutus'
import Service from './Pages/ServicePage/Service';
import LandingPage from './Pages/LandingPage/LandingPage';
import SupportPage from './Pages/SupportPage/SupportPage';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy/RefundPolicy';
import Termsconditions from './Pages/TermsCondition/Termscondition'
import 'aos/dist/aos.css';
import GuidePage from './Pages/GuidePage/GuidePage';
import { useEffect, useState } from 'react';
import Aos from 'aos';
import Login from './Pages/Login/Login';
import { useSelector } from 'react-redux';
import Notfound from './Pages/Notfound/Notfound';
import CareerPage from './Pages/CareerPage/CareerPage';


const development='dev'
// const development='sta'

const level=process.env.REACT_APP_LEVEL;



function App() {
  const {admin} = useSelector(state => state)
  const [login, setlogin] = useState(false)
  console.log(process.env.REACT_APP_LEVEL,'env');

  useEffect(() => {
   if(admin.username===process.env.REACT_APP_USERNAME && admin.password===process.env.REACT_APP_PASSWORD){
    setlogin(!login)
   }
  }, [admin])
  useEffect(()=>{
    Aos.init();
},[])
  return (
    <>
    { level=='development' ?
     
    <>
    <Router>
    <Routes>
    {!login && (
        <>
          
           <Route path='/login' element={<Login/>}/>
          <Route path='/service' element={<Navigate to={'/login'}/>} />     
          <Route path='/service/:screen' element={<Navigate to={'/login'}/>} />     
          <Route path='/about' element={<Navigate to={'/login'}/>} />     
          <Route path='/' element={<Navigate to={'/login'}/>} />     
          <Route path='/search' element={<Navigate to={'/login'}/>} />     
          <Route path='/guide' element={<Navigate to={'/login'}/>} />     
          <Route path='/guide/:section' element={<Navigate to={'/login'}/>} />     
          <Route path='/privacypolicy' element={<Navigate to={'/login'}/>} />     
          <Route path='/refundpolicy' element={<Navigate to={'/login'}/>} />     
          <Route path='/terms&conditions' element={<Navigate to={'/login'}/>} />     
          <Route path='/career' element={<Navigate to={'./login'}/>}/>

        </>
      )}
      {login && (
        <>
          <Route path='/login' element={<Navigate to={'/'}/>} /> 
         <Route path='/' element={<LandingPage/>}/>
         <Route path='/service' element={<Service/>}/>
         <Route path='/service/:screen' element={<Service/>}/>
         <Route path='/about' element={<Aboutus/>}/>
         <Route path='/search' element={<SupportPage/>}/>
         <Route path='/guide' element={<GuidePage/>}/>
         <Route path='/guide/:section' element={<GuidePage/>}/>
         <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
         <Route path='/refundpolicy' element={<RefundPolicy/>}/>
         <Route path='/terms&conditions' element={<Termsconditions/>}/>
         <Route path='/career'element={<CareerPage/>}/>
        </>
      )}
      
    </Routes>
  </Router>
      </> : 
 <>
 <Router>
  <Routes>
         <Route path='/login' element={<Navigate to={'/'}/>} />
         <Route path='/' element={<LandingPage/>}/>
         <Route path='/service' element={<Service/>}/>
         <Route path='/service/:screen' element={<Service/>}/>
         <Route path='/about' element={<Aboutus/>}/>
         <Route path='/search' element={<SupportPage/>}/>
         <Route path='/guide' element={<GuidePage/>}/>
         <Route path='/guide/:section' element={<GuidePage/>}/>
         <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
         <Route path='/refundpolicy' element={<RefundPolicy/>}/>
         <Route path='/terms&conditions' element={<Termsconditions/>}/>
         <Route path='/career'element={<CareerPage/>}/>
         <Route path={'*'} element={<Notfound/>}/>

  </Routes>

 </Router>
 </>

}
</>
  );
}

export default App;