import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import {
  RiFacebookFill,
  RiLinkedinFill,
  RiTwitterXFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiThreadsFill,
} from "react-icons/ri";

import axios from "../../axios";

const images = {
  catimg:
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/Group1171277084.webp",
};
const logo =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp",
  peteyeimg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/Frame1171275292.webp";

const LinkSection = () => {
  const [isNavigationOpen, setNavigationOpen] = useState(false);
  const [isLegalOpen, setLegalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailAdded, setEmailAdded] = useState(false);

  const toggleNavigation = () => {
    setNavigationOpen(!isNavigationOpen);
  };

  const toggleLegal = () => {
    setLegalOpen(!isLegalOpen);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() !== "") {
      axios
        .post("/web/emailsubscription", { email })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == "200") {
            setEmail("");
            setEmailAdded(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <section>
      <section className="container11">
        <div className="container-fluid container5 py-5">
          <h4 className="h1">PetEye's Inbox: Safe & Connected!</h4>
          <div className="container">
            <p className="paragraph-text">
              Sign up for product updates, offers, and learn more about PET EYE
              and its Services. Must be over 16 years to sign up.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit}>
              <div className="subscription-box">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="email-box"
                />
                <button type="submit" className="joinusbtn">
                  {" "}
                  {emailAdded ? "Joined !" : "Join Us"}
                </button>
              </div>
            </form>
          </div>
          <div className="container d-flex justify-content-center">
            <p className="bottom-text-subsciption">
              See our privacy statement to find out how we collect and use your
              data, to contact us with privacy questions, or to exercise your
              personal data rights.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-light text-dark py-5 d-none d-md-block navigationsec">
        <div className="container">
          <div className="">
            <div className="footer-content border-bottom mb-4">
              <div className="footer-content-sec-1">
                <div>
                  <b>NAVIGATION</b>
                  <div>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about#home">About Us</Link>
                    </li>
                    <li>
                      <Link to="/service/#service1">Services</Link>
                    </li>
                    <li>
                      <Link to="/guide/">Guide</Link>
                    </li>
                    <li>
                      <Link to="#">Shop</Link>
                    </li>
                    <li>
                      <Link to="/career/#careerhome">Careers</Link>
                    </li>
                    <li>
                      <Link to="/search/">Search</Link>
                    </li>
                  </div>
                </div>
                <div>
                  <b>LEGAL</b>
                  <div>
                    <li>
                      <Link to="/refundpolicy/">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/privacypolicy/">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms&conditions/">Terms and condition</Link>
                    </li>
                  </div>
                </div>
              </div>
              <div className="footer-content-sec-2">
                <div>
                  <img src={images.catimg} alt="petcare support" title="" />
                  <a href="mailto:care@peteye.pet">
                    <button className="primary-btn p-0">Contact Us</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="footer container d-none d-md-flex justify-content-between align-items-center">
          <div className="col-md-4 d-flex jusify-content-start">
            <div className="">
              <img src={logo} alt="img" title="" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="middle w-100 d-flex justify-content-center">
              <p> &copy;2023 K R PET EYE LLP. All Right Reserved.</p>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="socialmedia d-flex justify-content-end">
              <span>
                <a
                  href="https://www.facebook.com/PeteyeHub/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiFacebookFill />{" "}
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/company/kr-pet-eye-llp/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiLinkedinFill />
                </a>
              </span>
              <span>
                <a
                  href="https://twitter.com/Peteyehub"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiTwitterXFill />
                </a>
              </span>
              <span>
                <a
                  href="https://www.youtube.com/channel/UC4XYxZ8YILbvGPy4lUkHmqw"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiYoutubeFill />
                </a>
              </span>
              <span>
                <a
                  href="https://www.instagram.com/peteyehub?igsh=eGVkdHByN3RwZXZi"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiInstagramFill />
                </a>
              </span>
              <span>
                <a
                  href="https://www.threads.net/@peteyehub'"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  {" "}
                  <RiThreadsFill />
                </a>
              </span>
            </div>
          </div>
        </section>
      </section>
      {/* mobilefooter */}
      <section className="mobilefooter container">
        <div>
          <img
            className="mobilefooterlogo"
            src={logo}
            alt="peteye logo"
            title=""
          />
          <img src={peteyeimg} alt="peteye logo" title=" "/>
        </div>
        <div>
          <h4 onClick={toggleNavigation}>
            <span className="navplus">NAVIGATION</span>{" "}
            {isNavigationOpen ? "-" : "+"}
          </h4>
          {isNavigationOpen && (
            <div className="mfnav">
              <Link to="/">Home</Link>
              <Link to="/about#home">About us</Link>
              <Link to="/service/#service1">Service</Link>
              <Link to="/guide/">Guide</Link>
              <Link to="#">Shop</Link>
              <Link to="/career/#careerhome">Careers</Link>
              <Link to="/search/">Search</Link>

            </div>
          )}
        </div>
        <div>
          <h4 onClick={toggleLegal}>
            <span className="legalplus">LEGAL</span> {isLegalOpen ? "-" : "+"}
          </h4>
          {isLegalOpen && (
            <div className="mfnav">
              <Link to="/refundpolicy/">Refund Policy</Link>
              <Link to="/privacypolicy/">Privacy Policy</Link>
              <Link to="/terms&conditions/">Terms & Conditions</Link>
            </div>
          )}
        </div>
        <div className="mfsocial">
          <span>
            <a
              href="https://www.facebook.com/PeteyeHub/"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiFacebookFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.linkedin.com/company/kr-pet-eye-llp/"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiLinkedinFill />
            </a>
          </span>
          <span>
            <a
              href="https://twitter.com/Peteyehub"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiTwitterXFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.youtube.com/channel/UC4XYxZ8YILbvGPy4lUkHmqw"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiYoutubeFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.instagram.com/peteyehub?igsh=eGVkdHByN3RwZXZi"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiInstagramFill />
            </a>
          </span>
          <span>
            <a
              href="https://www.threads.net/@peteyehub'"
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              {" "}
              <RiThreadsFill />
            </a>
          </span>
        </div>

        <p className="mflasttext">
          &copy;2023 K R PET EYE LLP. All Right Reserved.
        </p>
      </section>
    </section>
  );
};

export default LinkSection;
