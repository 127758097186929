import React, { useEffect} from 'react';
import "./Policy.css";
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';



export default function Policy() {
    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header />
            </section>
            <div className='policy'>
                <section>
                    <div className='policysec1'  id='policy1'>
                        <h1>Privacy Policy</h1>
                    </div>
                </section>
                <div className='description1'>
                    <div>
                        <h2>SECTION 1 – INFORMATION COLLECTION </h2>
                        <h4>1.1 Personal Information Collection:  </h4>
                        <p>When you purchase K R PET EYE LLP, we collect the personal information you are willing to
                            provide, including your name, address and email address. Also, when you browse our store, we
                            automatically receive your computer’s IP address, which helps us to understand your browser
                            and operating system. </p>
                        <p>Additionally, as you browse the Site, we collect information about the individual web pages or
                            products that you view, what websites or search terms referred you to the Site, and information
                            about how you interact with the Site. We refer to this automatically-collected information as
                            “Device Information.” We collect Device Information using the following technologies: -
                            “Cookies” are data files that are placed on your device or computer and often include an
                            anonymous unique identifier.  </p>
                    </div>
                    <div>
                        <h3>SECTION 2 – CONSENT </h3>
                        <h4>2.1 Obtaining Your Consent:  </h4>
                        <p>We obtain your consent while you provide the personal information for specific purposes, such
                            as completing a transaction, verifying payment details, placing an order, arranging delivery, or
                            initiating a return. </p>
                        <h2>2.2 Secondary Purposes:  </h2>
                        <p>If we need your personal information for secondary purposes like marketing, we will either
                            directly request your consent or provide you with the option to decline. </p>
                        <h2>2.3 Withdrawing Consent:  </h2>
                        <p>You may withdraw your consent at any point in time by contacting us at care@peteye.pet or by
                            mailing us at K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad, 500029. </p>
                    </div>
                    <div>
                        <h3>SECTION 3 – INFORMATION DISCLOSURE </h3>
                        <p>We disclose your personal information only if required by law or in cases of violation of our
                            Terms and Conditions</p>
                        <h4>3.1 Disclosure of Data </h4>
                        <p>We may disclose personal information that we collect, or you provide:
                            Disclosure for Law Enforcement. Under certain circumstances, we may be required to disclose
                            your Personal Data if required to do so by law or in response to valid requests by public
                            authorities.
                            Business Transaction. If we or our subsidiaries are involved in a merger, acquisition or asset sale,
                            your Personal Data may be transferred.
                        </p>
                        <p>Other cases. We may disclose your information also:</p>
                        <p>(i) to our subsidiaries and affiliates;</p>
                        <p>(ii) to contractors, service providers, and other third parties we use to support our business;</p>
                        <p>(iii) to fulfil the purpose for which you provide it;</p>
                        <p>(iv) for the purpose of including your company’s logo or photos on our website;</p>
                        <p>(v) for any other purpose disclosed by us when you provide the information;</p>
                        <p>(vi) with your consent in any other cases;</p>
                        <p>(vii) if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others;</p>
                        <p>(viii) insurers, third-party underwriters, reinsurers, insurance intermediaries, regulators, law enforcement, the Financial Ombudsman Service (FOS) and the Office of the Arbiter for Financial Services</p>
                        <p>(ix) Price comparison websites (which you may have used to purchase your policy)</p>
                        <p>(x) Claims third-party administrators</p>
                        <p>(xi) Complaint third-party administrators</p>
                        <p>(xii) Approved suppliers and third parties we are contracted with, who provide a service related to the insurance policy</p>
                        <p>(xiii) Loss adjusters</p>
                        <p>(xiv) Regulatory authorities</p>
                        <p>(xv) Fraud prevention agencies</p>
                        <p>(xvi) Legal or crime prevention agencies</p>
                        <p>(xvii) Any additional insured parties who may communicate with us on your behalf, provided that they have your permission.</p>

                    </div>
                    <div>
                        <h3>SECTION 4 – PAYMENT PROCESSING</h3>
                        <p>For payment processing, we utilize the services of Razorpay Software Private Limited (“Razorpay”).</p>
                        <p>Neither PET EYE nor Razorpay stores your card data on their servers. Payment information is encrypted in accordance with the Payment Card Industry Data Security Standard (PCI-DSS) during payment processing. We retain your purchase transaction data only as long as necessary to complete the transaction. Our payment gateway adheres to PCI-DSS standards, ensuring secure handling of credit card information. For detailed information, you can review the terms and conditions of RazorPay at <a href="https://razorpay.com/terms/">https://razorpay.com/terms/</a>.</p>
                    </div>
                    <div>
                        <h3>SECTION 5 – THIRD-PARTY SERVICES</h3>
                        <p>We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                        <p>Our third-party service providers collect, use and disclose your information solely for the services they provide us. Payment gateways and transaction processors have their own privacy policies governing the information we provide for purchase-related transactions. We recommend reviewing these policies for a full understanding of how your personal information is handled. Please note that some providers may operate in different jurisdictions, subjecting your information to the laws of those jurisdictions when you use their services. Leaving our website or being redirected to a third-party site means you are no longer governed by our Privacy Policy or Terms and Conditions. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                        <p>We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                    </div>
                    <div>
                        <h3>SECTION 6 – SECURITY </h3>
                        <p>We take reasonable precautions and adhere to the industry’s best practices to protect your
                            personal information from loss, misuse, unauthorised access, disclosure, alteration, or
                            destruction. </p>
                        <p>The security of your data is important to us but remember that no method of transmission over
                            the Internet or method of electronic storage is 100% secure. While we strive to use commercially
                            acceptable means to protect your Personal Data, we cannot guarantee its absolute security. </p>
                    </div>
                    <div>
                        <h3>SECTION 7 – COOKIES</h3>
                        <p>We use cookies solely to maintain your user session and do not use them for personal identification on other websites.</p>

                        <h4>7.1 Tracking Cookies Data</h4>
                        <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>

                        <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>

                        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may be unable to use some portions of our Service.</p>

                        <p>Examples of Cookies we use:</p>

                        <ul>
                            <li><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
                            <li><strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                            <li><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
                            <li><strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                        </ul>

                    </div>
                    <div>
                        <h3>SECTION 8 – AGE OF CONSENT</h3>
                        <p>By using our site, you confirm that you are of legal age in your state or province of residence or that you have given consent for any of your minor dependents to use our site.</p>
                    </div>
                    <div>
                        <h3>SECTION 9 – CHANGES TO THIS PRIVACY POLICY</h3>
                        <p>We reserve the right to modify this privacy policy at any time. We recommend reviewing it periodically for changes and clarifications, which take effect immediately upon posting on our website. In the event of material changes, we will notify you of the update. If our store is acquired or merged with another company, your information may be transferred to the new owners to continue serving you.</p>
                    </div>
                    <div>
                        <h3>SECTION 10 – PET DATA MANAGEMENT</h3>

                        <h4>10.1 Pet Data Collection and Usage</h4>
                        <p>In addition to personal information, we may also collect and process data related to your pets. This data may include but is not limited to your pet's name, breed, age and medical history. We use this information to provide you with personalized pet care recommendations and improve our services.</p>

                        <h4>10.2 Pet Data Security</h4>
                        <p>We take the security of your pet data seriously and implement reasonable safeguards to protect it. However, please be aware that no method of transmission over the Internet or electronic storage is entirely secure.</p>

                        <h4>10.3 We May Collect Information From Other Sources</h4>
                        <ul>
                            <li>Price comparison websites, if you have obtained a quotation for a policy with us. The information you provide is shared with us and used for the purposes set out below.</li>
                            <li>The information in this policy will apply in addition to any information given to you by the price comparison website about the use of your personal information;</li>
                            <li>Third-party service providers whom we have a relationship with.</li>
                            <li>Publicly available sources of information, such as social media and networking sites.</li>
                            <li>Third-party databases made available to the insurance industry as well as databases where you have given your permission to share information with a third party like us. Which include but are not limited to fraud databases.</li>
                        </ul>

                        <h4>10.4 Data Retention</h4>
                        <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

                    </div>
                    <div>
                        <h3>SECTION 11 – LOCATION INFORMATION</h3>

                        <h4>11.1 Collection of Location Information</h4>
                        <p>When using our mobile applications, we may collect and process information about your device's location, including precise geographic data such as GPS coordinates, Wi-Fi signals, and Bluetooth beacons, as well as general location data such as city or region.</p>
                    </div>
                    <div>
                        <h3>SECTION 12 – HOW WE USE YOUR LOCATION INFORMATION</h3>

                        <p>We use location information for:</p>
                        <ul>
                            <li>Service Functionality: To provide location-based services, such as maps, directions and location-specific features.</li>
                            <li>Improving our Services: To analyze user behaviour, track trends and enhance our mobile applications.</li>
                        </ul>

                        <p>We use the Order Information that we collect generally to fulfil any orders placed through the app (including processing your payment information and providing you with invoices and/or order confirmations).</p>
                        <p>Additionally, we use this Order Information to:</p>
                        <ul>
                            <li>Communicate with you;</li>
                            <li>Screen our orders for potential risk or fraud; and</li>
                            <li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
                        </ul>

                        <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our app (for example, by generating analytics about how our customers browse and interact with the app, and to assess the success of our marketing and advertising campaigns).</p>
                    </div>
                    <div>
                        <h3>SECTION 13 – YOUR CHOICES</h3>

                        <p>You can enable or disable location services through your device settings. However, disabling location services may affect the functionality of some app features.</p>
                    </div>
                    <div>
                        <h3>SECTION 14 – SECURITY OF LOCATION DATA</h3>

                        <p>While we implement reasonable safeguards to protect your location data, please be aware that no transmission over the Internet or electronic storage is entirely secure.</p>
                    </div>
                    <div>
                        <h3>SECTION 15 – CHANGES TO LOCATION DATA POLICY</h3>

                        <p>We may update this Location Data Policy within our mobile applications to reflect changes in our practices or for operational, legal or regulatory reasons.</p>
                        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    </div>
                    <div>
                        <h3>SECTION 16 - MARKETING</h3>

                        <p>With your explicit permission, we may send you information about our pet products, new arrivals and other relevant updates including but not limited to Emails, Internet, mobile and other digital marketing, Operating a Refer a Friend scheme</p>
                        <p>Behavioural Remarketing: We may use remarketing services to advertise on third-party websites to you after you visit our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
                    </div>
                    <div>
                        <h3>SECTION 17 – CONTACT INFORMATION</h3>

                        <p>For questions, concerns or requests related to this Privacy Policy or the use of location data in our mobile applications, please contact us at <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by mail at K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad, 500029.</p>

                        <p>Thank you for choosing PetEye for Pet Supplies. We are committed to respecting your privacy and ensuring responsible and transparent handling of your information.</p>

                        <h3>QUESTIONS AND CONTACT INFORMATION</h3>

                        <p>For access to, correction of, amendment to or deletion of your personal information, to register a complaint or for further information, please contact our Privacy Compliance Officer at <a href="mailto:care@peteye.pet">care@peteye.pet</a> or by mail at K R PET EYE LLP, 15th Street, Himayat Nagar, Hyderabad, 500029.</p>

                    </div>
                </div>
            </div>
            <section>
                <Footer />
            </section>

        </div>
    )
};
